'use strict';

const baseSearch = require('brand_core/components/search');

/**
 * Suggestions wrapper toggle
 * @param {Object} scope - Search field DOM element
 */
function toggleSuggestionsWrapperContent(scope) {
    const $suggestionsWrapper = baseSearch.getSuggestionsWrapper(scope);
    // TODO: GSD-4432 : This line is specific to Sorel's styling
    $suggestionsWrapper.removeClass('d-none').addClass('visible d-block');

    const $quicklinks = $suggestionsWrapper.find('.js-search-quicklinks');
    const $suggestions = $suggestionsWrapper.find('.js-search-suggestions');
    if ($suggestions.text().replace(/\s/g, '').length !== 0) {
        $quicklinks.removeClass('d-block').addClass('d-none');
        $suggestions.removeClass('d-none').addClass('d-block');
    } else {
        $suggestions.removeClass('d-block').addClass('d-none');
        if ($(scope).val().length) {
            $quicklinks.removeClass('d-block').addClass('d-none');
        } else {
            $quicklinks.removeClass('d-none').addClass('d-block');
        }
    }
}

/**
 * Open Desktop Search
 * @param {jQuery} $searchOpen jquery reference search element to open
 */
function openDesktopSearch($searchOpen) {
    $.disableScroll();
    // TODO: GSD-4432 : Sorel styling specific: core has 'js-show-search expanded'
    $searchOpen.addClass('js-show-search');
    $('.js-search-field').trigger('focus');
    // close search when it loses focus
    $searchOpen.loseFocus(baseSearch.tearDownSearch, '.globalbanner, header, .page, footer');
    // TODO: GSD-4432 : Sorel styling specific: not in brand_core
    $('.sitenav-primary__ul').addClass('invisible');
}

/**
 * Tear down search and close popover
 * @param {jQuery} $target jquery reference to target element
 */
function tearDownSearch($target) {
    $(baseSearch.headerSearchContainerSelector).removeClass('js-show-search');
    $('.suggestions-wrapper').removeClass('visible d-block').addClass('d-none');
    $('.sitenav-primary__ul').removeClass('invisible');

    if ($target && $target.hasClass('js-action-search-close')) {
        $('.js-search-suggestions').empty();
        $('.js-search-field').val('');
    }
    $.enableScroll();
}

/**
 * Initialize functions
 */
function init() {
    baseSearch.keyboardNav.init();
    baseSearch.searchEvents();
}

module.exports = function () {
    baseSearch.tearDownSearch = tearDownSearch;
    baseSearch.openDesktopSearch = openDesktopSearch;
    baseSearch.toggleSuggestionsWrapperContent = toggleSuggestionsWrapperContent;
    init();
};
