'use strict';

const base = require('brand_core/components/headerKeyboardNav');
const keyboardAccessibility = require('./keyboardAccessibility');

/**
 *  Prev sibling menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function prevSiblingMenuItem(thisLi) {
    let $focusTarget = $(thisLi).closest('.sitenav-secondary__menu').prev().find('li:visible')
        .first();

    if ($focusTarget.length) {
        $focusTarget.trigger('focus');
    }
}

/**
 *  Next sibling menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function nextSiblingMenuItem(thisLi) {
    let $focusTarget = $(thisLi).closest('.sitenav-secondary__menu').next().find('li:visible')
        .first();

    if ($focusTarget.length) {
        $focusTarget.trigger('focus');
    } else if ($(thisLi).closest('.sitenav-primary__li:not(.sitenav__search)').find('.sitenav__category-content').length) { // check for slotted content
        base.findAnchorItem($(thisLi).closest('.sitenav-primary__li:not(.sitenav__search)'));
    }
}

/**
 * keyboard events for secondary anchors inside open flyout menu
 */
base.secondaryNav = function () {
    keyboardAccessibility('.sitenav-secondary__li',
        {
            13: function () { // enter
                base.navigateLink(this);
                return true;
            },
            37: function () { // left
                let $this = $(this);
                if ($this.prevAll('li:visible').length && $this.hasClass('flex-lg-column')) { // more items in column
                    base.previousMenuItem(this);
                } else {
                    prevSiblingMenuItem(this);
                }
                return true;
            },
            38: function (parentLi) { // up
                if ($(this).prevAll('li:visible').length) {
                    base.previousMenuItem(this);
                } else {
                    base.previousMenuLevel(parentLi);
                }
                return true;
            },
            39: function () { // right
                let $this = $(this);
                if ($this.nextAll('li:visible').length && $this.hasClass('flex-lg-column')) { // more items in column
                    base.nextMenuItem(this);
                } else {
                    nextSiblingMenuItem(this);
                }
                return true;
            },
            40: function () { // down
                let $this = $(this);
                if ($this.find('li:visible').length) {
                    base.nextMenuLevel(this, 'li:visible');
                } else if ($this.nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                } else {
                    nextSiblingMenuItem(this);
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-primary__li');
        }
    );
};

base.tertiaryNav = function () {
    keyboardAccessibility('.sitenav-tertiary__li',
        {
            13: function () { // enter
                base.navigateLink(this);
                return true;
            },
            38: function (parentLi) { // up
                if ($(this).prevAll('li:visible').length) {
                    base.previousMenuItem(this);
                } else {
                    base.previousMenuLevel(parentLi);
                }
                return true;
            },
            40: function (parentLi) { // down
                if ($(this).nextAll('li:visible').length) {
                    base.nextMenuItem(this);
                } else if (parentLi.nextAll('li:visible').length) {
                    base.nextMenuItem(parentLi);
                } else {
                    nextSiblingMenuItem(parentLi); // roll over to next column in navigation if it exists
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-secondary__li');
        }
    );
};

base.init = function () {
    base.skipLinkNav();
    base.primaryNav();
    base.secondaryNav();
    base.tertiaryNav();
    base.sitenavCategoryContent();
};

module.exports = base;
