const baseMenu = require('brand_core/components/menu');
const keyboardNav = require('./headerKeyboardNav');
const countrySelectorKeyboardNav = require('brand_core/components/countrySelectorKeyboardNav');
const breakpoints = require('../components/breakpoints');
const debounce = require('lodash/debounce');

const $sitenav = $('.sitenav');
const $sitenavMenu = $('.sitenav__menu');
const $siteheaderMenu = $('.menutoggle-button');

/**
 * If selected menu item has a submenu, hide it
 * @param {string} selected - selected menu item
 * @returns {bool} if submenu exists
 */
function closeMainNav(selected) {
    const openItem = $(selected);
    if (openItem.length) {
        openItem.removeClass('open').attr('aria-expanded', false);
        return true;
    }
    return false;
}

/**
 * Toggle open/close state on mobile navigation menu
 */
function toggleMobileNav() {
    const $openNav = $('.sitenav-primary__li.open');
    baseMenu.closeSubNav();
    $sitenavMenu.removeClass('open');
    $('html').removeClass('menu-active');
    $sitenav.removeClass('open');
    $siteheaderMenu.toggleClass('open close');
    // close secondary menus on toggle
    closeMainNav($openNav);
    $.enableScroll();
}

/**
 * Open the mobile navigation menu
 */
function mouseEvents() {
    // only for desktop

    if (breakpoints.isHighRes()) {
        let $openLi = null;
        let hasMouseEnteredSecondaryArea = false;
        let timeoutHandle = null;

        $sitenav
            .on('mouseleave.breakpointDependent', '.sitenav-primary__li', function () {
                $(this).find('.open').removeClass('open');
            });

        $sitenav
            .on('click.breakpointDependent', '.sitenav-secondary__li', function () {
                $(this).parent().find('.open').removeClass('open');
                $(this).openMenuItem();
            });

        $sitenav
            .on('mouseleave.breakpointDependent', '.sitenav-secondary', function () {
                hasMouseEnteredSecondaryArea = true; // If leaving sitenav-secondary then mouse has been in sitenav-secondary
                if ($openLi) {
                    timeoutHandle = setTimeout(function () {
                        $openLi.closeMenuItem();
                    }, 400);
                }
            });

        $sitenav
            .on('mouseenter.breakpointDependent', '.sitenav-secondary', function () {
                clearTimeout(timeoutHandle);
                if ($openLi) {
                    $openLi.openMenuItem();
                }
            });

        $sitenav
            .on('mouseenter.breakpointDependent', '.sitenav-primary__li', function () {
                clearTimeout(timeoutHandle);
                hasMouseEnteredSecondaryArea = false;

                if ($openLi !== null) {
                    $openLi.closeMenuItem(); // mouse moved to another sitenav-primary__li
                }

                $(this).openMenuItem();
                $openLi = $(this);
            });

        $sitenav
            .on('mouseleave.breakpointDependent', $openLi, function () {
                if (!hasMouseEnteredSecondaryArea && $openLi !== null) { // Want menu to close instantly if mouse has not entered sitenav-secondary
                    $openLi.closeMenuItem();
                    hasMouseEnteredSecondaryArea = false;
                }
            });
    }

    // only for mobile
    if (breakpoints.isLowRes()) {
        // open menu
        $siteheaderMenu
            .on('click.breakpointDependent', function (e) {
                e.preventDefault();
                if ($(this).hasClass('open')) {
                    baseMenu.openMobileNav();
                    $(this).toggleClass('open close');
                } else {
                    toggleMobileNav();
                }
            });

        // open primary menu level
        $sitenav
            .on('click.breakpointDependent', '.sitenav-primary__a', function (e) {
                if (baseMenu.openSubNav(this, '.sitenav-secondary__menu-categories, ul')) {
                    e.preventDefault();
                }
            });

        // open secondary menu level
        $sitenav
            .on('click.breakpointDependent', '.sitenav-secondary__a', function (e) {
                if (baseMenu.openSubNav(this, 'ul')) {
                    e.preventDefault();
                }
            });

        // previous menu
        $sitenav
            .on('click.breakpointDependent', '.js-action__menuback', function (e) {
                e.preventDefault();
                baseMenu.backMobileNav();
            });
    }
}

/**
 * Miscellaneous events
 */
function miscEvents() {
    // always set the SOR mobile menu icon state to 'open' by default on window resize/reload events
    $siteheaderMenu.removeClass('close').addClass('open');
}

/**
 * Events triggered on screen size
 */
function resizeEvents() {
    let lastWidth = $(window).width();
    const isWidthResized = debounce(function () {
        if ($(window).width() !== lastWidth) {
            lastWidth = $(window).width();
            $(window).trigger('widthResize');
        }
    }, 300);

    const reloadEvents = function () {
        // clear all breakpoint dependent events
        $('*').off('.breakpointDependent');
        mouseEvents(); // load SOR specific mouse event function
        miscEvents();

        if (breakpoints.isHighRes()) {
            baseMenu.closeMobileNav();
        }
    };

    // reload events on screen resize
    $(window).on('resize.reloadEvents', isWidthResized);
    $(window).on('widthResize.reloadEvents', reloadEvents);
}

/**
* If selected menu item has a submenu, toggle displaying it
* @param {string} selected - selected menu item anchor
* @param {string} selector - sub navigation target element
* @returns {bool} if submenu exists
*/
function toggleSubNav(selected, selector) {
    const $parentLi = $(selected).parent('li');
    const $subNav = $parentLi.find(selector).first();
    if ($subNav.length) {
        if ($subNav.hasClass('open')) { // close subnav
            $parentLi.closeMenuItem();
            $subNav.removeClass('open');
        } else { // open subnav
            $parentLi.openMenuItem();
            $subNav.addClass('open');
        }
        return true;
    }
    return false;
}

/**
 * Close secondary navigation menus
 */
function closeSecondarySubNav() {
    $('.sitenav-secondary__menu-categories, ul').closeMenuItem();
}

/**
 * Close teritary navigation menus
 */
function closeTertiarySubNav() {
    $('.sitenav-secondary__li').closeMenuItem();
    $('.sitenav-tertiary__ul').removeClass('open');
}

/**
 * close submenus
 */
function closeSubNav() {
    closeTertiarySubNav();
    closeSecondarySubNav();
}

/**
 * Back or close the mobile navigation menu
 */
function backMobileNav() {
    const openSecondaryLevel = $('.sitenav-secondary__menu-categories.open, ul.open').length;
    const openTertiaryLevel = $('.sitenav-tertiary__ul.open').length;

    if (openTertiaryLevel) {
        closeTertiarySubNav();
    } else if (openSecondaryLevel) {
        closeSecondarySubNav();
    }
}

/**
 * Initialize SOR menu functions
 */
function init() {
    mouseEvents();
    baseMenu.keyboardEvents();
    baseMenu.focusEvents();
    keyboardNav.init();
    countrySelectorKeyboardNav.init();
    baseMenu.desktopTapEvents();
    baseMenu.miscEvents();
    baseMenu.scrollEvents();
    resizeEvents();
}

module.exports = function () {
    baseMenu.openSubNav = toggleSubNav;
    baseMenu.backMobileNav = backMobileNav;
    baseMenu.closeSubNav = closeSubNav;
    init();
};
